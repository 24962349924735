.Cart {
}

.Cart-items {
    list-style: none;
    min-height: 25em;
    padding: 0;

    @media screen and (max-width: 767px) {
        min-height: auto;
    }
}
.Cart-items--checkout {
    min-height: auto;
    padding-top: var(--gutter);
}
.Cart-items--receipt {
    min-height: auto;
}

.Cart-item {
    background-color: var(--color-cart-row);
    display: flex;
    padding: var(--gutter);

    &.is-disabled {
        color: var(--color-faded);
        pointer-events: none;
    }

    & + & {
        margin-top: var(--gutter);
    }
}

.Cart-itemImage {
    aspect-ratio: 2 / 3;
    display: flex;
    margin: 0;
    width: 10%;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        width: 18%;
    }
}
.Cart-itemText {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: var(--gutter);

    @media screen and (max-width: 767px) {
        flex-flow: row wrap;
        justify-content: space-between;
    }
}
.Cart-itemTextRow {
    display: flex;

    @media screen and (max-width: 767px) {
        &:first-child {
            display: block;
            margin-bottom: var(--gutter);
        }
    }
}
.Cart-itemTextRow--bottom {
    margin-top: auto;

    @media screen and (max-width: 767px) {
        margin-top: var(--gutter);
        width: 100%;
    }
}
.Cart-itemQtyButtons {
    padding-left: 1em;
}
.Cart-itemQtyButton {
    color: var(--color-accent);
    padding: 0 0.35em;

    @media screen and (max-width: 767px) {
        display: none;
    }

    &.is-active {
        color: inherit;

        @media screen and (max-width: 767px) {
            display: block;
        }
    }

    @media (hover) {
        &:hover {
            color: inherit;
        }
    }
}
.Cart-itemRemove {
    padding: 0;

    @media (hover) {
        &:hover {
            color: var(--color-accent);
        }
    }
}

.Cart-footer {
    display: grid;
    gap: var(--gutter);
    padding: var(--gutter) 0;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-flow: column;
    }
}
.Cart-footer--header {
    padding-bottom: 0;
}
.Cart-footerInfo {
    background-color: var(--color-cart-row);
    display: block;
    padding: var(--gutter);
}
.Cart-footerInfoText {
    color: var(--color-accent);
    display: block;
    margin-bottom: 0.25rem;
}
.Cart-footerInfoValue {
}
.Cart-checkoutLink {
    background-color: var(--color-contrast);
    color: var(--color-base);
    display: block;
    grid-column: span 2;
    padding: var(--gutter);
}

.Form {
}

.Form-heading {
    margin-bottom: var(--gutter);
}

.Form-form {
    display: flex;
    flex-flow: column;
    gap: var(--gutter);
    margin-top: calc(var(--gutter) * 2);
}

.Form-input,
.Form-textarea,
.Form-select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    width: 100%;
}

.Form-input {
    height: var(--line-height-regular);
}

.Form-textarea {
    min-height: 6em;
    resize: vertical;
}

.Form-select {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.Form-inlineButton {
    bottom: 0;
    padding: var(--gutter);
    position: absolute;
    right: 0;
}

.Form-submit {
    margin-left: auto;

    &:disabled {
        color: var(--color-accent);
    }
}

.Form-error,
.Form-success {
    margin-top: var(--gutter);
    color: var(--color-accent);
}

.Confirmation {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
        object-fit: cover;
    }
}
.Confirmation-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--gutter);
    width: 100%;
}

.Confirmation-backToHomeButton {
    display: block;
    margin-top: 2rem;
    text-align: left;
    width: 100%;

    &:hover {
        color: var(--color-accent);
    }
}

.adyen-drop-in-container {
    font-size: initial;
    line-height: initial;

    .adyen-checkout__payment-method {
        border-radius: 0 !important;
    }
    .adyen-checkout__payment-method--selected {
        background-color: var(--color-cart-row);
    }
}

.Checkout {
    column-gap: var(--gutter);
    display: grid;
    flex: 1;
    grid-template-columns: repeat(2, 1fr);
    margin-top: var(--header-height);
    padding: 0 var(--gutter);

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.Checkout-column {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--gutter);
}

.Checkout-header {
    align-items: center;
    display: flex;
}
.Checkout-headerButton {
    color: var(--color-accent);
    margin-right: calc(var(--gutter) / 2);
    padding: 0;

    &:after {
        content: "→";
        margin-left: calc(var(--gutter) / 2);
        position: relative;
        top: -0.05em;
    }
}
.Checkout-title {
}

.Checkout-labels {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
    padding: var(--gutter) 0;

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.Checkout-label {
    background-color: var(--color-cart-row);
    display: block;
    padding: var(--gutter);
    position: relative;
    text-align: left;

    &.is-disabled {
        opacity: 0.75;
    }

    a {
        text-decoration: underline;
    }
}
.Checkout-label--half {
}
.Checkout-label--full {
    grid-column: span 2;
}
.Checkout-label--transparent {
    background-color: transparent;
}
.Checkout-returnLink,
.Checkout-backButton,
.Checkout-nextButton {
    display: block;
    padding: var(--gutter);
    text-align: left;
    width: 100%;

    &:hover {
        color: var(--color-accent);
    }
}
.Checkout-returnLink {
    background-color: var(--color-cart-row);
    grid-column: span 2;
}
.Checkout-backButton {
    background-color: var(--color-cart-row);
}
.Checkout-nextButton {
    background-color: var(--color-contrast);
    color: var(--color-base);
    grid-column: span 2;
}

.Checkout-labelValue--light {
    color: var(--color-accent);
}

.Checkout-labelValue--row {
    display: flex;
}

.Checkout-labelText,
.Checkout-labelText--notice,
.Checkout-labelText--full {
    color: var(--color-accent);
    display: block;
    margin-bottom: 0.25rem;

    &:last-child {
        margin-bottom: 0;
        margin-top: 0.25rem;
    }

    .Checkout-label.is-active & {
        color: inherit;
    }

    abbr {
        font-size: 0.9em;
        line-height: 1;
        text-decoration: none;
        vertical-align: top;
    }
    abbr:before,
    abbr:after {
        font-size: 0.75em;
        vertical-align: 0.35em;
    }
    abbr:before {
        content: "(";
    }
    abbr:after {
        content: ")";
    }
}

.Checkout-labelText--full {
    grid-column: span 2;
}

.Checkout-labelText--notice {
    font-style: italic;
    margin-bottom: var(--gutter);
}

.Checkout-fauxSelect {
    display: flex;
    justify-content: space-between;
}
.Checkout-input,
.Checkout-textarea,
.Checkout-select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    width: 100%;
}
.Checkout-input {
    height: var(--line-height-regular);
}
.Checkout-textarea {
    min-height: 6em;
    resize: vertical;
}
.Checkout-select {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.Checkout-inlineButton {
    bottom: 0;
    padding: var(--gutter);
    position: absolute;
    right: 0;
}

.Checkout-checkbox {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.Checkout-fauxCheckbox {
    margin-top: 0.25rem;
    position: relative;
    display: inline-flex;
    height: 2rem;
    padding: 0.1rem;
    background-color: var(--color-cart);
}

.Checkout-fauxCheckbox--single {
    position: relative;
    display: inline-flex;
    background-color: var(--color-cart);
    padding: 0.1rem;
    padding-right: 1rem;
    margin-top: 0.25rem;
    p {
        color: var(--color-contrast);
        margin: auto 0 auto var(--gutter);
    }
}

.Checkout-fauxCheckbox--singleBox {
    position: relative;
    width: 1.8rem;
    height: auto;
    aspect-ratio: 1/1;
    background-color: var(--color-cart-row);
    input:checked ~ & {
        &::after {
            content: "";
            position: absolute;
            width: 1.2rem;
            height: auto;
            aspect-ratio: 1/1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--color-contrast);
        }
    }
}

.Checkout-fauxCheckboxYes,
.Checkout-fauxCheckboxNo {
    color: var(--color-accent);
    width: 3.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Checkout-fauxCheckboxYes {
    background-color: transparent;
    color: var(--color-accent);
    input:checked ~ & {
        color: inherit;
        background-color: var(--color-contrast);
        color: var(--color-base);
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
        }
    }
}
.Checkout-fauxCheckboxNo {
    background-color: transparent;
    color: var(--color-accent);

    input:not(:checked) ~ & {
        color: inherit;
        background-color: var(--color-contrast);
        color: var(--color-base);
        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 100%;
        }
    }
}

.Checkout-ingrid {
    @media screen and (max-width: 767px) {
        margin-bottom: var(--gutter);
    }
}
.Checkout-adyen {
    padding-top: var(--gutter);
}

.Checkout-footer {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
    margin-top: auto;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-flow: column;

        .Checkout-column:first-child & {
            margin-bottom: var(--gutter);
            order: -1;
        }
    }
}

.Checkout-error {
    background: var(--color-cart);
    color: var(--color-accent);
    line-height: var(--line-height-regular);
    padding: 1em;
}

.Checkout-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: black, $alpha: 0.5);
}

.Checkout-confirmModal {
    display: flex;
    flex-direction: column;
    padding: var(--gutter);
    position: fixed;
    grid-template-columns: repeat(4, 1fr);
    min-height: 40rem;

    bottom: 0;
    left: 0;
    width: 100%;

    background-color: var(--color-base);
    z-index: 999;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: 0.5s transform ease;
    transform: translateY(100%);

    .Checkout-summaryInformationWrapper {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: var(--gutter);
            margin-bottom: var(--gutter);
        }
        @media screen and (max-height: 720px) {
            row-gap: 0;
            margin-bottom: var(--gutter);
        }
    }

    .Checkout-summaryBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--gutter);
        margin: var(--gutter) 0;
        margin-top: 0;
        grid-column: span 2;
        ul {
            padding: 0;
            li {
                display: flex;
                gap: var(--gutter);
                p {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .Checkout-summaryBox--items {
        gap: 0;
        .Checkout-summaryItemWrapper {
            margin-top: var(--gutter);
            p {
                margin-bottom: 0 !important;
            }
        }
    }

    .Checkout-summaryListsWrapper {
        display: flex;
        ul {
            width: 50%;
        }
    }

    .Checkout-summaryHeader {
        color: var(--color-accent);
    }

    .Checkout-summaryHeader--items {
        margin-top: var(--gutter) !important;
    }

    .Checkout-navigationButtonsWrapper {
        margin-top: auto;
        display: flex;
        gap: var(--gutter);
    }

    &.--animate {
        transform: translateY(0);
    }
}

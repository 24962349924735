.ZoomGallery {
    background-color: var(--color-base);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
}

.ZoomGallery-wrapper {
    height: 100%;
    overflow: auto;
}

.ZoomGallery-image {
    height: auto;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    padding: calc(var(--gutter) / 2) var(--gutter);
    width: 100%;
}

.Grid-error {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
        object-fit: cover;
    }
}

.Product-error {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    gap: var(--gutter);
    align-items: center;
    justify-content: center;
}

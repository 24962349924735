.TrackOrder {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
        object-fit: cover;
    }
}

.TrackOrder-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-right: 7px;

    @media screen and (max-width: 767px) {
        margin: 0;
    }
}

.TrackOrder-formWrapper {
    width: 100%;
}

.TrackOrder-heading {
    margin-bottom: var(--gutter);
}

.TrackOrder-form {
    display: flex;
    justify-content: center;
    width: 100%;
}

.TrackOrder-input {
    background-color: var(--color-cart-row);
    border: none;
    display: block;
    flex: 1;
    height: 100%;
    outline: none;
    padding: var(--gutter);
    position: relative;
    text-align: left;
    width: 100%;
}

.TrackOrder-sendButton {
    background-color: var(--color-cart-row);
    display: block;
    padding: 1rem 2rem;
    text-align: left;

    &:hover {
        color: var(--color-accent);
    }

    @media screen and (max-width: 767px) {
        padding: 1rem;
        text-align: right;
    }
}

.TrackOrder-widgetContainer {
    align-items: center;
    display: flex;
    height: 90%;
    justify-content: center;
}

.TrackOrder-widget {
    flex: 1;
}

.TrackOrder-backToStoreBtn {
    display: block;
    padding: var(--gutter);
    text-align: left;
    width: 100%;

    &:hover {
        color: var(--color-accent);
    }
}

@import "node_modules/normalize.css/normalize";
@import "_reset";
@import "_fonts";
@import "_vars";
@import "_utilities";
@import "Cart";
@import "Checkout";
@import "Grid";
@import "Header";
@import "Product";
@import "Projects";
@import "Popup";
@import "Error";
@import "TrackOrder";
@import "Subscribe";
@import "Confirmation";
@import "ZoomGallery";
@import "Form";

// stylelint-disable-next-line
#__next {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
}

html {
    font-size: var(--base-size);
}
body {
    background-color: var(--color-base);
    color: var(--color-contrast);
    font-family: var(--font-family-regular);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);

    &.is-checkout {
        background-color: var(--color-cart);
    }
}
a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: var(--color-accent);
    }
}
button {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;

    &:enabled {
        cursor: pointer;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font: inherit;
    margin: 0;
}
p:not([class]):not(:last-child) {
    margin-bottom: var(--line-height-regular);
}
sup {
    top: 0.5em;
}
/* End Base */

:root {
    /* Base */
    --base-size: clamp(10px, 0.9vw, 18px);
    /* UI */
    --gutter: 1rem;
    --blank-space-width: 0.25em;
    --grid-block-height: calc(((100vw - (var(--gutter) * 2)) / 4) * 1.5);
    --grid-block-height--large: calc(((100vw - var(--gutter)) / 2) * 1.5);
    --grid-block-height--landscape: calc((100vw - (var(--gutter) * 2)) * 1.33);
    /* Fonts */
    --font-family-regular: "Antique Legacy", sans-serif;
    --font-size-regular: 1rem;
    --line-height-regular: 1.1rem;
    --font-weight-regular: 400;
    /* Colors */
    --color-base: #fff;
    --color-black-opacity: hsla(0, 0%, 0%, 0.175);
    --color-contrast: #000;
    --color-accent: #666;
    --color-alt-accent: #788284;
    --color-brown-accent: #b98045;
    --color-faded: #e5e8ee;
    --color-cart: #e5e8ee;
    --color-cart-row: #f4f3f2;
    /* Transitions */
    --transition-duration: 0.3s;
    --transition-timing-function: ease-in-out;
}

@media screen and (max-width: 767px) {
    :root {
        --base-size: clamp(14px, 0.9vw, 18px);
        --color-faded: #00000020;
        --grid-block-height: calc(((100vw - var(--gutter)) / 2) * 1.5);
        --grid-block-height--large: calc((100vw - var(--gutter)) * 1.5);
        --grid-block-height--landscape: calc((100vw - var(--gutter)) * 1.5);
        //--line-height-regular: 1rem;
    }
}

@mixin richtext {
    * {
        font-size: var(--font-size-regular);
        font-weight: var(--font-weight-regular);
        line-height: var(--line-height-regular);
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        margin: 0 0 var(--line-height-regular);
        padding: 0;
        width: 100%;
        li:not(:last-child):after {
            content: ",";
            display: inline-block;
            margin-right: var(--blank-space-width);
        }
        // li:hover,
        li.is-active a {
            color: var(--color-accent);
        }
    }
}

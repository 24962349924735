.Product {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    padding: calc(var(--gutter) / 2);
    position: relative;

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(8, 1fr);
    }
}

.Product--flushBottom {
    padding-bottom: 0;
}

.Product-header {
    align-items: flex-start;
    background-color: var(--color-base);
    display: flex;
    padding: var(--gutter);
    position: fixed;
    right: 0;
    top: 0;
    width: calc(50% + (var(--gutter) / 2));
    z-index: 50;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.Product-headerName {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);
    padding-right: var(--blank-space-width);
    width: calc(50% + (var(--gutter) / 2));
}

.Product-headerRight {
    flex: 1;

    @media screen and (max-width: 767px) {
        align-items: flex-end;
        display: flex;
        flex: unset;
        flex-flow: column;
        margin-left: auto;
    }
}

.Product-headerPrice {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);

    .Product.is-soldOut & {
        text-decoration: line-through;
    }
}

.Product-headerAdd {
    border: none;
    padding: 0;
    text-align: left;

    @media (hover) {
        &:enabled:hover {
            color: var(--color-accent);
        }
    }
}

.Product-galleryImage {
    aspect-ratio: 2 / 3;
    // cursor: pointer;
    display: flex;
    width: 100%;
    grid-column: span 4;
    margin: 0;
    padding: calc(var(--gutter) / 2);

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.Product-galleryImage--big {
    grid-column: span 8;
    grid-row: span 2;
}

.Product-content {
    align-items: flex-start;
    aspect-ratio: 4 / 3;
    display: flex;
    flex-wrap: wrap;
    grid-column: span 8;
    padding-top: 4rem;

    >div {
        display: flex;

        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
    }

    @media screen and (max-width: 939px) {
        aspect-ratio: 2 / 3;
    }

    @media screen and (max-width: 767px) {
        flex-flow: column;
        padding-top: 0;
    }

    @media screen and (min-width: 767px) {
        grid-row: span 2;
        margin-top: auto;
    }
}

.Product-contentColumn {
    flex-flow: column;
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);
    padding: calc(var(--gutter) / 2);
    width: 50%;

    @media screen and (max-width: 767px) {
        order: 2;
        width: 100%;
    }

    >div+div {
        margin-top: var(--line-height-regular);
    }
}

.Product-contentColumn--images {
    display: flex;
    width: 100%;

    @media screen and (max-width: 767px) {
        flex-flow: row nowrap !important;
        order: 1;
    }
}

.Product-contentColumn--first {
    @media screen and (max-width: 767px) {
        order: 0;
    }
}

.Product-outOfStock {
    background-color: var(--color-cart-row);
    display: block;
    margin-top: var(--gutter);
    min-width: 20rem;
    padding: var(--gutter);
    position: relative;
    text-align: left;

    @media screen and (max-width: 450px) {
        flex: 1;
    }
}

.Product-outOfStockResponse {
    color: var(--color-accent);
    padding: var(--gutter) 0;

    @media screen and (max-width: 767px) {
        text-align: right;
    }
}

.Product-notifyLabel {
    color: var(--color-accent);
    display: block;
    margin-bottom: 0.25rem;
}

.Product-notifyButton {
    display: block;
    flex: 1;
    padding: var(--gutter) 0;
    position: relative;
    text-align: left;

    &.is-disabled {
        opacity: 0.75;
    }

    a {
        text-decoration: underline;
    }
}

.Product-outOfStockInput {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    height: var(--line-height-regular);
    outline: 0;
    padding: 0;
    width: 100%;
}

.Product-zoomGallery {
    display: none;
}

.Product-galleryVisible {
    display: block;
}

.-disableScroll {
    overflow: hidden;
}
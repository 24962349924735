/** @define Popup; weak */

.Popup-wrapper {
    display: flex;
    inset: 0;
    outline: none;
    padding: var(--gutter);
    pointer-events: none;
    position: absolute;
}

.Popup-modal {
    background-color: var(--color-base);
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 45em;
    pointer-events: all;
    position: relative;
    width: 100%;
}

.Popup-close {
    margin-left: auto;
    padding: var(--gutter);
    z-index: 2;
}

.Popup-formWrapper {
    padding: 0 var(--gutter) var(--gutter);
}

:root {
    --header-height: calc(var(--gutter) * 2 + var(--line-height-regular));
}

.Header {
    bottom: 0;
    column-gap: var(--gutter);
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    left: 0;
    padding: 0 var(--gutter);
    position: fixed;
    width: 100%;
    z-index: 100;

    @media screen and (max-width: 939px) {
        grid-template-columns: repeat(4, 1fr);
        &.is-open {
            padding-bottom: calc(
                var(--gutter) + (var(--line-height-regular) * 2)
            );
        }
    }
}
.Header--top {
    bottom: auto;
    top: 0;
}

.Header-notices {
    bottom: var(--header-height);
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
}
.Header-notices--top {
    bottom: auto;
    top: var(--header-height);
}

.Header-notice {
    align-items: center;
    background-color: var(--color-alt-accent);
    column-gap: var(--gutter);
    display: grid;
    grid-auto-rows: 1;
    grid-template-columns: repeat(16, 1fr);
    height: var(--header-height);
    justify-items: start;
    padding: 0 var(--gutter);
    position: relative;

    &.is-hidden {
        margin-bottom: calc(var(--header-height) * -1);
        transition: margin 0.3s ease, visibility 0.3s step-end;
        visibility: hidden;
    }
    .Header-notices--top &.is-hidden {
        margin-bottom: 0;
        margin-top: calc(var(--header-height) * -1);
    }
}
.Header-notice--brown {
    background-color: var(--color-brown-accent);
}

.Header-notice--grey {
    background-color: var(--color-cart-row);
}

.Header-notice--no-padding-left {
    padding-left: 0;
}

.Header-noticeHeading {
    grid-column: span 2;

    @media screen and (max-width: 939px) {
        grid-column: span 5;
    }
}
.Header-noticeText {
    grid-column: span 13;

    @media screen and (max-width: 939px) {
        animation: mobileNoticeAniation 15s linear infinite;
        grid-column: span 10;
        transform: translateX(calc(100% - 10rem));
        white-space: pre;
    }
}
.Header-noticeText1 {
    grid-column: span 2;

    @media screen and (max-width: 939px) {
        grid-column: span 4;
    }
}
.Header-noticeText2 {
    grid-column: span 11;

    @media screen and (max-width: 939px) {
        grid-column: span 6;
    }
}
.Header-noticeButton {
    padding: 0;
    position: relative;
    z-index: 10;
}

.Header-notice-newsletterForm {
    grid-column: span 15;
    width: 100%;
}

.Header-notice-newsletterSuccess {
    padding-left: var(--gutter);
}

.Header-background {
    background-color: var(--color-base);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    transition: background-color var(--transition-duration) linear;
    width: 100%;
    z-index: -1;

    .Header.is-showingCart & {
        background-color: var(--color-cart);
    }
    .Header--top & {
        background-color: var(--color-cart);
        bottom: auto;
        top: 0;
    }
    .Header--top.is-open & {
        background-color: var(--color-base);
    }
}

@keyframes mobileNoticeAniation {
    0% {
        transform: translateX(calc(100% - 10rem));
    }
    100% {
        transform: translateX(-100%);
    }
}

.Header-block {
    display: flex;
    flex-direction: column;
    grid-column-end: 17;
    grid-row: 2;
    justify-content: flex-end;
    padding: var(--gutter) 0;

    .Header--top & {
        flex-direction: column-reverse;
    }
    .Header.is-showingCart & {
        grid-row: 1;
    }

    &.is-selected {
        .Header-list {
            @media screen and (max-width: 939px) {
                z-index: 1;
            }
        }

        @media screen and (max-width: 939px) {
            a {
                pointer-events: initial;
                p {
                    display: block;
                }
            }
        }
    }

    a {
        &:hover {
            color: var(--color-accent) !important;
        }
        p {
            display: none;
        }
    }

    &:not(.is-selected) {
        .Header-content,
        .Header-list {
            .Header:not(.is-focused) & {
                @media screen and (max-width: 939px) {
                    visibility: hidden;
                }
            }
        }
    }

    @media screen and (max-width: 939px) {
        grid-column-end: 5;
        width: 100%;
        a:not(.Header-title):not(.Header-blockHeading),
        > div:not([class]) {
            width: 25%;

            .Header.is-open:not(.is-focused) & {
                color: var(--color-faded);
            }
        }

        .Header.is-open &:not(.Header-block--title):not(.Header-block--bag) {
            &.is-selected {
                a {
                    color: var(--color-contrast);
                }
                > *:last-child {
                    color: var(--color-contrast);
                }
            }
        }
    }
}

.Header-block--title {
    grid-column-start: 1;
    justify-content: space-between;
    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration));
    }

    @media screen and (max-width: 939px) {
        grid-row: 1;
        .Header-content {
            bottom: 0;
            left: 0;
            padding: var(--gutter);
            position: absolute;
            width: 19rem;
        }
    }
}

.Header-title {
    align-self: flex-start;
    display: block;

    span:last-child {
        display: inline-block;
        transform: translateX(-40%);
        transition: transform var(--transition-duration)
            var(--transition-timing-function) var(--transition-duration);
    }

    .Header.is-open .Header-block--title:hover & span:last-child {
        transform: none;
    }

    @media screen and (max-width: 939px) {
        .Header.is-open .Header-block--title & span:last-child {
            transform: none;
        }
    }
}

.Header-block--store,
.Header-block--projects,
.Header-block--about,
.Header-block--location,
.Header-block--currency,
.Header-block--support {
    .Header.is-showingCart & {
        color: var(--color-faded);
        pointer-events: none;
    }

    > a {
        @media screen and (max-width: 939px) {
            pointer-events: none;
        }
    }

    &.is-selected {
        > *:last-child {
            visibility: visible;
        }
    }

    @media screen and (max-width: 939px) {
        flex-direction: row-reverse !important;
    }
}
.Header-block--store {
    grid-column-start: 3;

    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration) * 1);
    }

    @media screen and (max-width: 939px) {
        column-gap: var(--gutter);
        display: none;
        flex-flow: row-reverse;
        grid-column-start: 1;

        .Header.is-open & {
            display: flex;
        }
    }
}
.Header-block--projects {
    grid-column-start: 4;

    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration) * 1.5);
    }

    @media screen and (max-width: 939px) {
        column-gap: var(--gutter);
        display: none;
        flex-flow: row-reverse;
        grid-column-start: 1;
        left: 0;
        position: absolute;
        top: calc(var(--line-height-regular) * 3);

        .Header.is-open & {
            display: flex;
        }
    }
}
.Header-block--about {
    grid-column-start: 5;

    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration) * 2);
    }

    @media screen and (max-width: 939px) {
        column-gap: var(--gutter);
        display: none;
        flex-flow: row-reverse;
        grid-column-start: 1;
        left: 0;
        position: absolute;
        top: calc(var(--line-height-regular) * 6);

        .Header.is-open & {
            display: flex;
        }
    }
}
.Header-block--location {
    grid-column-start: 13;

    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration) * 2.5);
    }

    @media screen and (max-width: 939px) {
        display: none;
        grid-column-start: 1;
        left: 0;
        position: absolute;
        top: 0;
    }
}
.Header-block--support {
    grid-column-start: 15;

    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration) * 3);
    }

    @media screen and (max-width: 939px) {
        column-gap: var(--gutter);
        display: none;
        flex-flow: row-reverse;
        grid-column-start: 1;
        left: 0;
        position: absolute;
        top: calc(var(--line-height-regular) * 9);

        .Header.is-open & {
            display: flex;
        }
    }
}

.Header-block--bag {
    grid-column-start: 16;

    .Header-content,
    .Header-list {
        animation-delay: calc(var(--transition-duration) * 3.5);
    }

    .Header--top & {
        pointer-events: none;
    }

    @media screen and (max-width: 939px) {
        grid-column-start: 4;
        grid-row: 1;
    }
}

.Header-block--toggle {
    display: none;

    @media screen and (max-width: 939px) {
        display: block;
        grid-column-end: 4;
        grid-column-start: 3;
        grid-row: 1;
        button {
            padding: 0;
            &:after {
                content: "Menu";
                .Header.is-open &,
                .Header.is-showingCart & {
                    content: "Close";
                }
            }
        }
    }
}

.Header-blockHeading {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    width: calc((100vw - (var(--gutter) * 17)) / 16);

    sup {
        margin-left: 1em;
    }

    @media screen and (max-width: 939px) {
        width: 100%;
    }
}
.Header-countryButton {
    padding: 0;
}

@keyframes Header-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Header-content,
.Header-list {
    animation-duration: var(--transition-duration);
    animation-fill-mode: both;
    animation-timing-function: var(--transition-timing-function);
    color: var(--color-faded);
    height: 0;
    list-style: none;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    transition: color var(--transition-duration)
        var(--transition-timing-function) ease-in-out;

    .Header.is-open &,
    .Header.is-showingCart & {
        height: auto;
    }
    .Header.is-open & {
        animation-name: Header-fadeIn;
    }
    .Header.is-showingCart & {
        opacity: 0;
        transition: opacity var(--transition-duration) linear;
    }
    .Header.is-open .Header-block:hover & {
        animation-delay: var(--transition-duration);
        color: var(--color-contrast);
        pointer-events: all;
        z-index: 100;
    }

    @media screen and (max-width: 939px) {
        color: inherit;

        a {
            .Header-block:not(.is-selected) & {
                pointer-events: none;
            }
        }
    }
}

.Header-content,
.Header-list {
    @media screen and (max-width: 939px) {
        width: 75%;
        li {
            white-space: nowrap;

            a {
                .is-selected & {
                    pointer-events: initial;
                }
            }
        }
    }
}

.Header-content--top {
    margin-bottom: auto;

    @media screen and (max-width: 939px) {
        display: none;
    }
}

.Header-cartWrapper {
    animation-delay: var(--transition-duration);
    animation-duration: var(--transition-duration);
    animation-fill-mode: both;
    animation-timing-function: var(--transition-timing-function);
    display: none;
    grid-column: 1 / 17;
    grid-row: 1;
    grid-template-columns: repeat(2, 50%);
    margin-bottom: calc(var(--gutter) * 2 + var(--line-height-regular));
    padding-top: var(--gutter);

    .Header.is-showingCart & {
        animation-name: Header-fadeIn;
        display: grid;
    }

    @media screen and (max-width: 939px) {
        grid-column: 1 / 5;
        grid-row: 2;
        margin-bottom: var(--gutter);
        padding-top: 0;
    }
}
.Header-cart {
    grid-column: 2;
    padding-left: calc(var(--gutter) / 2);

    @media screen and (max-width: 939px) {
        grid-column: 1 / 5;
        padding-left: 0;
    }
}

.Subscribe {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
        object-fit: cover;
    }
}

.Subscribe-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-right: 7px;

    @media screen and (max-width: 767px) {
        margin: 0;
    }
}

.Subscribe-formWrapper {
    width: 100%;
}

.Subscribe-heading {
    margin-bottom: var(--gutter);
}

.Subscribe-form {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Subscribe-input {
    background-color: var(--color-cart-row);
    border: none;
    display: block;
    flex: 1;
    height: 100%;
    outline: none;
    padding: var(--gutter);
    position: relative;
    text-align: left;
    width: 100%;
}

.Subscribe-messageWrapper {
    padding: var(--gutter);
}

.Subscribe-sendButton {
    background-color: var(--color-cart-row);
    display: block;
    padding: 1rem 2rem;
    text-align: left;

    &:hover {
        color: var(--color-accent);
    }

    @media screen and (max-width: 767px) {
        padding: 1rem;
        text-align: right;
    }
}

.Subscribe-error {
    color: var(--color-brown-accent);
    font-size: 0.875rem;
}

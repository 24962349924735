.u-richText {
    h2,
    h3,
    p em {
        float: left;
        font-style: normal;
        width: 33%;
    }

    h3 + p:not([class]):not(:last-child) {
        margin-bottom: 0;
    }

    ol {
        counter-reset: section;
        display: flex;
        flex-flow: column;
        list-style: none;
        margin: 0 0 var(--line-height-regular);
        padding: 0;
        width: 100%;
        li {
            counter-increment: section;
            padding-left: 1rem;
            position: relative;
            &:before {
                content: "(" counter(section) ")";
                display: block;
                float: left;
                font-size: 75%;
                left: 0;
                position: absolute;
                top: 0;
                vertical-align: text-top;
                width: 1.1rem;
            }
        }
    }

    dl {
        display: flex;
        flex-flow: row wrap;
        dt {
            margin: 0;
            padding: 0;
            width: 25%;
        }
        dd {
            margin: 0;
            padding: 0;
            width: 75%;
        }
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        margin: 0 0 var(--line-height-regular);
        padding: 0;
        width: 100%;

        li:not(:last-child):after {
            content: ",";
            display: inline-block;
            margin-right: var(--blank-space-width);
        }
        li.is-active a {
            color: var(--color-accent);
        }
    }

    .clear {
        color: var(--color-accent);
        padding: 0;
    }
}
